import { type Data } from 'core/domain/Request'
import { type HttpClientConfig, type HttpClientInterface } from 'core/infra/http/HttpClient'

import { type ApprovalStatus } from '../domain/UserAccount'
import { mapApiToUserAccount, type RawUserAccount } from './userAccountMapper'

type UpdateUserApprovalStatusParams = {
  approvalStatus: ApprovalStatus
  userId: string
}

type UpdateUserAccountParams = {
  data: {}
  config?: Partial<HttpClientConfig>
}

export type UserAccountRepositoryDependencies = {
  httpClient: HttpClientInterface
}

export class UserAccountRepository {
  private httpClient: HttpClientInterface

  constructor({ httpClient }: UserAccountRepositoryDependencies) {
    this.httpClient = httpClient
  }

  async getUserAccount(config?: Partial<HttpClientConfig>) {
    const endpoint = `/api/v2/account`

    const {
      data: { data },
    } = await this.httpClient.get<Data<RawUserAccount>>(endpoint, config)

    return mapApiToUserAccount(data)
  }

  async updateUserAccount({ data, config }: UpdateUserAccountParams) {
    const endpoint = `/api/v2/account`

    const result = await this.httpClient.put<Data<RawUserAccount>>(endpoint, data, config)

    return mapApiToUserAccount(result.data.data)
  }

  async getUserAccounts(approvalStatus: ApprovalStatus, config?: Partial<HttpClientConfig>) {
    const endpoint = `/api/v2/carrier/accounts?approval_status=${approvalStatus}`

    const {
      data: { data },
    } = await this.httpClient.get<Data<Array<RawUserAccount>>>(endpoint, config)

    return data.map(mapApiToUserAccount)
  }

  async updateUserApprovalStatus(
    { approvalStatus, userId }: UpdateUserApprovalStatusParams,
    config?: Partial<HttpClientConfig>
  ) {
    const endpoint = `/api/v2/carrier/accounts/${userId}/approval`

    await this.httpClient.post(
      endpoint,
      {
        approval_status: approvalStatus,
      },
      config
    )
  }
}
