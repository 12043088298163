import { AxiosHeaders } from 'axios'

import { storage } from 'legacy/utils/storage'

const API_URL = process.env.REACT_APP_API_URL

export const http = {
  isExpired() {
    const EXPIRATION_TIME_IN_SECONDS = 30
    let creation = storage.get('loadSmart.user-tokenCreation')
      ? storage.get('loadSmart.user-tokenCreation')
      : 0

    creation = Math.floor(parseInt(creation, 10) / 1000)

    let now = new Date()
    now = Math.floor(now.getTime() / 1000)

    let limit = EXPIRATION_TIME_IN_SECONDS - (now - creation)

    return limit < 0
  },
  getHeaders() {
    const token = storage.get('loadSmart.user-token')

    const header = {
      baseURL: API_URL,
      headers: new AxiosHeaders({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    }

    return header
  },
  getHeadersV2() {
    const token = storage.get('loadSmart.user-token')

    return {
      baseURL: API_URL,
      headers: new AxiosHeaders({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    }
  },
  getPublicHeaders() {
    return {
      baseURL: API_URL,
      headers: new AxiosHeaders({
        Authorization: 'Basic YWJjMTIzOnNzaC1zZWNyZXQ=',
        'Content-Type': 'application/json',
      }),
    }
  },
  getPublicHeadersV2() {
    return {
      baseURL: API_URL,
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
      }),
    }
  },
  getPublicHeadersForm(client = {}) {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }

    if (client && client.client_id && client.client_secret) {
      const authorization = window.btoa(`${client.client_id}:${client.client_secret}`)
      headers.Authorization = `Basic ${authorization}`
    }

    return {
      baseURL: API_URL,
      headers: new AxiosHeaders(headers),
    }
  },
}

export default http
