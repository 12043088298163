import { type HttpClientConfig, type HttpClientInterface } from 'core/infra/http/HttpClient'

import { type SignUp } from '../domain/SignUp'
import { mapSignUpToApi } from './HubspotMapper'

const CARRIER_FORM_ID = '6f4af1b4-d050-455e-b506-cd978de8c166'
const PORTAL_ID = '5041527'

export type HubspotRepositoryDependencies = {
  httpClient: HttpClientInterface
}

export class HubspotRepository {
  private httpClient: HttpClientInterface

  constructor({ httpClient }: HubspotRepositoryDependencies) {
    this.httpClient = httpClient
  }

  submitHubspot(signUp: SignUp, config?: Partial<HttpClientConfig>) {
    const endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${CARRIER_FORM_ID}`

    const rawHubspot = mapSignUpToApi(signUp)

    return this.httpClient.post(endpoint, rawHubspot, config)
  }
}
